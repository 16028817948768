.app__error {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;

  background-color: var(--alice-blue);
  color: var(--onyx);

  transition: background-color 0.25s ease-out;
}

.app__error > div > h1 {
  margin-bottom: 1rem;
  font-size: 4rem;
  color: var(--neon-blue);
}
