@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  /* Font Family */
  font-family: "Poppins", sans-serif;

  /* Primary Colors for Base and Text */
  --primary-black: #333333; /* Dark base for text */
  --primary-white: #ffffff; /* Main background color */
  
  /* Gradient Colors */
  --gradient-start: #ff7e5f; /* Starting color of the gradient */
  --gradient-end: #feb47b; /* Ending color of the gradient */

  /* Secondary Colors */
  --secondary-yellow: #ffbe70; /* Warm, inviting secondary color */
  --secondary-red: #feb47b; /* For alerts or important notifications */
  --secondary-red-transparent: #ffcda79b; /* For navbar */

  /* Accent Colors */
  --accent-light-grey: #d3d3d3; /* For borders or subtle elements */
  --accent-neon-blue: #0051ff; /* Vibrant accent color for CTAs */

  /* Neutral Colors */
  --neutral-light: #f5f5f5; /* Light background for sections */
  --neutral-dark: #383c43; /* Darker background for contrast */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0.75rem; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--neutral-light);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-light-grey);
  border-radius: 10px;
  border: 2px solid var(--neutral-light);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gradient-end);
}

::-webkit-scrollbar-thumb:active {
  background: var(--accent-neon-blue);
}
