.app__home {
  background-color: var(--neutral-light);
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  padding: 0;
  margin: 0;
  border: none;

  transition: background-color 0.25s ease-out;
}

.app__home-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  min-height: 100vh;
  height: 100%;
  width: 100%;

  background: linear-gradient(
    135deg,
    var(--gradient-start),
    var(--gradient-end)
  );
}

@media (max-width: 768px) {
  .app__home-hero {
    justify-content: flex-start;
  }
}

.app__home-hero-nav {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;

  z-index: 50;

  left: 0;
  top: 0;
  gap: 5rem;

  user-select: none;
  -webkit-user-drag: none;

  padding: 2rem;
  width: 100%;

  transition: background-color 0.25s ease, padding 0.4s ease;
}

@media (max-width: 500px) {
  .app__home-hero-nav {
    flex-direction: column;
    gap: 1rem;
  }

  .app__home-hero-nav > a > img {
    display: none;
  }
}

.app__home-hero-nav > a {
  text-decoration: none;
  font-weight: 900;
  color: var(--neutral-dark);
  cursor: pointer;
  font-size: clamp(1rem, 2.5vw + 0.5rem, 1.2rem);
  text-transform: uppercase;
}

.app__home-hero-presentation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .app__home-hero-presentation {
    flex-direction: column;
    margin-top: 10rem;
  }
}

.app__home-hero-text {
  display: flex;
  gap: 2.5rem;
  padding: 8rem;
  max-width: 50%;

  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  height: 100%;
  width: 50%;

  user-select: none;
  -webkit-user-drag: none;
}

@media (max-width: 768px) {
  .app__home-hero-text {
    min-width: 100%;
    padding: 2em;
  }
}

.app__home-hero-text > h1 {
  width: 100%;
  font-size: clamp(1.5rem, 4vw + 1rem, 2.5rem);
  font-weight: 800;

  color: var(--accent-neon-blue);
}

@media (max-width: 768px) {
  .app__home-hero-text > h1 {
    text-align: center;
  }
}

.app__home-hero-list-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--primary-white);

  width: 100%;
}

.app__home-hero-list-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: clamp(1rem, 2.5vw + 0.5rem, 1.2rem);
  gap: 0.5rem;
}

.app__home-hero-button > a {
  background-color: var(--accent-neon-blue);
  color: var(--primary-white);
  font-weight: 800;
  font-size: 1.15rem;
  padding: 1rem 2.5rem;
  text-decoration: none;

  border-radius: 50px;
  border: 2px solid transparent;
  box-shadow: 0px 8px 20px rgba(0, 81, 255, 0.3);
  transition: all 0.3s ease;
}

.app__home-hero-button > a:hover {
  background-color: var(--secondary-yellow);
  border: 2px solid var(--primary-white);
  box-shadow: 0px 12px 30px rgba(255, 190, 112, 0.4);

  transform: translateY(-3px) scale(1.05);
  cursor: pointer;
}

.app__home-hero-button > a:active {
  transform: translateY(0) scale(0.95);

  box-shadow: 0px 5px 15px rgba(255, 190, 112, 0.2);
}

.app__home-hero-img {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 50%;
}

.app__home-hero-img > img {
  min-width: 100%;

  user-select: none;
  -webkit-user-drag: none;
}

@media (max-width: 768px) {
  .app__home-hero-img {
    padding: 1.5rem;
    justify-content: center;
    width: 100%;
  }
}

.app__home-body-left {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  flex-wrap: wrap;

  min-height: 100vh;

  height: 100%;
  width: 100%;

  background-color: var(--neutral-light);
  transition: background-color 0.25s ease-out;
}

.app__home-body-left-img {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  padding: 3rem;
}

#label {
  display: flex;
  color: var(--accent-neon-blue);
  font-weight: 500;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: absolute;
  margin-top: 2.5rem;
  transform: translateX(50%);
  padding: 1rem 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--neutral-light);
}

#left-right-img {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  user-select: none;
  border-radius: 20px;
  width: 70%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);

  user-select: none;
  -webkit-user-drag: none;
}

#left-right-img > img {
  object-fit: contain;
  object-position: 50%;
  aspect-ratio: 9/16;
  height: 450px;
  background: linear-gradient(
    135deg,
    var(--gradient-start),
    var(--gradient-end)
  );
  width: 100%;
  box-sizing: border-box;

  user-select: none;
  -webkit-user-drag: none;
}

@media (max-width: 768px) {
  .app__home-body-left-img {
    justify-content: center;
    width: 100%;
  }

  #left-right-img {
    width: 100%;
    justify-content: center;
  }

  #label {
    transform: translateX(0);
    font-size: 0.75rem;
  }
}

.app__home-body-left-text {
  padding: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 3rem;
  width: 50%;
}

@media (max-width: 768px) {
  .app__home-body-left-text {
    width: 100%;
    padding: 2rem;
  }
}

.app__home-body-left-text > h1 {
  font-size: clamp(1.5rem, 4vw + 1rem, 2.5rem);
  font-weight: 800;
  color: var(--secondary-yellow);

  user-select: none;
  -webkit-user-drag: none;
}

.app__home-body-left-text > p {
  font-weight: 400;
  line-height: 1.5rem;
  font-size: clamp(1rem, 4vw + 1rem, 1.25rem);

  user-select: none;
  -webkit-user-drag: none;
}

@media (max-width: 768px) {
  .app__home-body-left-text > p {
    font-size: 0.9rem;
  }
}

.app__home-body-button > a {
  background-color: var(--secondary-yellow);
  color: var(--primary-white);
  font-weight: 600;
  font-size: 1.15rem;
  padding: 1rem 2.5rem;
  text-decoration: none;

  border-radius: 50px;
  border: 2px solid transparent;
  box-shadow: 0px 8px 20px var(--secondary-yellow);
  transition: all 0.3s ease;

  user-select: none;
  -webkit-user-drag: none;
}

.app__home-body-button > a:hover {
  background-color: var(--accent-neon-blue);
  border: 2px solid var(--primary-white);
  box-shadow: 0px 12px 30px var(--accent-neon-blue);

  transform: translateY(-3px) scale(1.05);
  cursor: pointer;
}

.app__home-body-button > a:active {
  transform: translateY(0) scale(0.95);

  box-shadow: 0px 5px 15px rgba(255, 190, 112, 0.2);
}
/* Testimonial section */

.app__home-body-right {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  flex-wrap: wrap;

  min-height: 100vh;

  height: 100%;
  width: 100%;

  background: linear-gradient(
    135deg,
    var(--gradient-end),
    var(--gradient-start)
  );
  transition: background-color 0.25s ease-out;
}

.app__home-body-right-text {
  padding: 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;

  user-select: none;
  -webkit-user-drag: none;
}

@media (max-width: 768px) {
  .app__home-body-right-text {
    width: 100%;
    padding: 2rem;
  }
}

.app__home-body-right-text > h1 {
  font-size: clamp(1.5rem, 4vw + 1rem, 2.5rem);
  font-weight: 800;
  color: var(--accent-neon-blue);
}

.app__home-body-right-text > p {
  font-weight: 400;
  line-height: 1.5rem;
  color: var(--neutral-light);
  font-size: clamp(1rem, 4vw + 1rem, 1.1rem);
}

@media (max-width: 768px) {
  .app__home-body-right-text > p {
    font-size: 0.9rem;
  }
}

.app__home-body-right-img {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
  padding: 3rem;
}

@media (max-width: 768px) {
  .app__home-body-right-img {
    justify-content: center;
    width: 100%;
  }
}

.app__home-body-right-img > #left-right-img > img {
  background: none;
  background-color: var(--neutral-light) !important;
}

.app__home-body-right-text-table {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  min-height: 100%;
  color: var(--neutral-light);
  width: 100%;
  font-weight: 600;
}

@media (max-width: 768px) {
  .app__home-body-right-text-table {
    grid-template-columns: repeat(3, 1fr);
  }
}


.subscribe-form {
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribe-form form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  padding: 30px;

  font-family: "Poppins";
  color: var(--neutral-dark);
}

@media (max-width: 768px) {
  .subscribe-form form {
    width: 90%;
  }
}

.subscribe-form h2 {
  font-size: clamp(1rem, 1vw + 1rem, 2rem);
  text-align: center;
  width: 100%;
  font-weight: 800;
  color: var(--accent-neon-blue);
  margin-bottom: 1rem;
}

.subscribe-form input {
  color: var(--neutral-dark);
  width: 75%;
  padding: .8rem;
  margin: 10px 0;
  border-radius: 30px;
  border: 1px solid var(--accent-light-grey);
  outline: none;
  transition: border-color 0.5s;
}

.subscribe-form input:focus {
  border-color: var(--accent-neon-blue);
}

.subscribe-form button {
  margin-top: 1rem;
  background-color: var(--accent-neon-blue);
  color: var(--primary-white);
  font-weight: 800;
  font-size: 1.15rem;
  padding: 1rem 2.5rem;
  text-decoration: none;

  border-radius: 50px;
  border: 2px solid transparent;
  box-shadow: 0px 8px 20px rgba(0, 81, 255, 0.3);
  transition: all 0.3s ease;
}

.subscribe-form button:hover {
  background-color: var(--secondary-yellow);
  border: 2px solid var(--primary-white);
  box-shadow: 0px 12px 30px rgba(255, 190, 112, 0.4);

  transform: translateY(-3px) scale(1.05);
  cursor: pointer;
}

.subscribe-form button:active {
  transform: translateY(0) scale(0.95);

  box-shadow: 0px 5px 15px rgba(255, 190, 112, 0.2);
}