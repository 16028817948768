.app__footer {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  overflow: hidden;

  background-color: var(--neutral-light);
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid var(--neutral-dark);
  border-width: 1px 0 0 0;

  transition: background-color 0.25s ease;
}

.app__footer-copyright {
  display: flex;
  margin-right: 2rem;
  width: 50%;
  color: var(--neutral-dark);
  font-weight: 400;
  font-size: 0.9rem;
  font-family: "Ubuntu", "sans-serif";
}

.app__footer-links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  
  gap: 1rem;

  width: 50%;
  height: 100%;
  

  color: var(--neutral-dark);
  font-size: 1rem;
}

.app__footer-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 0.25rem;
}

.app__footer-link > a {
  text-decoration: none;
  font-weight: 500;
  font-size: clamp(1rem, 2vw, 1rem);
  color: var(--secondary-red);
}
